<template>
  <exc-header
    :nav-links="navigationLinks"
    :only-theme="onlyTheme"
    :is-trade="isTrade"
    :class="{
      'exchange-header__is-trade bor bor--bottom bor--transp-02': isTrade,
    }"
    class="exchange-header"
    @toggle="onToggleMenu"
  >
    <template #logo>
      <div class="pl-16--m pr-8 pr-16--m">
        <exc-header-nav-item :to="localePath('/')">
          <template #name>
            <exc-logo
              class="exchange-header__logo"
              :class="{
                'txt--color-08': onlyTheme === 'dark',
                'txt--color-01': onlyTheme === 'light',
                'txt--theme-08': !onlyTheme,
              }"
              aria-label="C-Patex"
            />
          </template>
        </exc-header-nav-item>
      </div>
    </template>

    <!-- <div>
      <component :is="userLinks.icon" />
    </div> -->

    <!-- ///////////////////////////////// DESKTOP RIGHT PART ///////////////////////////////// -->
    <template #desktop>
      <!-- login and register buttons -->
      <transition name="fade" mode="out-in">
        <template v-if="!isLogined">
          <div class="exchange-header__auth-btns flx flx--items-center">
            <exc-button
              class="mr-16"
              size="xs"
              outline
              color="main"
              :to="localePath('/login')"
            >
              <span
                class="txt--14px txt--weight-600 txt--height-18px txt--height-20px--l"
              >
                {{ $t('header.logIn') }}
              </span>
            </exc-button>
            <exc-button size="xs" :to="localePath('/register')">
              <span
                class="txt--14px txt--color-01 txt--weight-600 txt--height-18px txt--height-20px--l"
              >
                {{ $t('header.createAccount') }}
              </span>
            </exc-button>
          </div>
        </template>
      </transition>

      <!-- Profile -->
      <transition name="fade">
        <exc-header-nav-item
          v-if="isLogined"
          dropdown-align="right"
          :only-theme="onlyTheme"
        >
          <template #name>
            <div class="rdx-header-desktop__account-logo" />
          </template>
          <template #dropdown>
            <div class="pl-16 pr-16 mb-10 mt-10">
              <span
                class="txt--weight-500 txt--16px txt--height-20px txt--color-04"
              >
                {{ userSettings.email }}
              </span>
            </div>
            <div
              class="flx flx--items-center pl-24 pr-16 mb-10 exchange-header__status-wrapper"
              :class="statusClasses"
            >
              <div class="exchange-header__kyc-icon mr-6" />
              <span
                class="exchange-header__kyc-text txt--weight-600 txt--12px txt--height-16px"
              >
                {{ kysStatus }}
              </span>
            </div>
            <exc-header-dropdown-item
              v-for="menuItem in userLinks.menu"
              :key="menuItem.name"
              v-bind="{
                ...menuItem,
                label: undefined,
                name: undefined,
                onlyTheme: onlyTheme,
              }"
              @click="removeLocale(menuItem.logout)"
            >
              <template #left>
                <component :is="menuItem.icon" />
              </template>
              <template #name>
                <span
                  class="rdx-header-desktop__user-item flx flx--items-center"
                >
                  {{ menuItem.name }}
                  <exc-header-label-creator
                    v-if="menuItem.label"
                    :label="menuItem.label"
                    class="txt--height-14px txt--weight-400 txt--12px"
                  />
                </span>
              </template>
              <template v-if="menuItem.description" #description>
                {{ menuItem.description }}
              </template>
            </exc-header-dropdown-item>
          </template>
        </exc-header-nav-item>
      </transition>

      <!-- Language-->
      <exc-header-nav-item class="exchange-header__lang-wrapper">
        <template #name>
          <span @click="$emit('select-language')">
            <img
              :src="require('@/assets/img/select-language.svg')"
              width="32px"
              height="32px"
              alt="Language"
            >
            {{
              MixinNavLangSwitch_currentLanguage &&
                MixinNavLangSwitch_currentLanguage.name
                ? MixinNavLangSwitch_currentLanguage.name
                : ''
            }}
          </span>
        </template>
      </exc-header-nav-item>

      <!-- Theme switcher -->
      <!-- <exc-header-nav-item
        class="exchange-header__theme-switch txt--pointer"
        :only-theme="onlyTheme"
        @click="themeToggle"
      >
        <template #name>
          <div class="exchange-header__theme-switch-icon-container">
            <moon-big-icon v-show="isDarkThemeEnabled" />
            <sun-big-icon v-show="!isDarkThemeEnabled" />
          </div>
        </template>
      </exc-header-nav-item> -->
    </template>

    <template #close-icon>
      <div class="rxd-header-mob__mob-close-icon">
        <div class="icon" />
      </div>
    </template>

    <!-- ///////////////////////////// MOBILE AUTH PART //////////////////// -->
    <template #mobile-auth="{ toggleSidebar }">
      <!-- show buttons for not logged in user -->
      <div
        v-if="!isLogined"
        class="exchange-header__auth-btns flx flx--items-center pl-14 pr-14 mt-8 mb-8"
        @click="toggleSidebar"
      >
        <exc-button size="xs" class="mr-10" :to="localePath('/register')">
          <span
            class="txt--14px txt--14px--l txt--weight-600 txt--height-18px txt--height-20px--l"
          >
            {{ $t('header.createAccount') }}
          </span>
        </exc-button>
        <exc-button size="xs" outline color="main" :to="localePath('/login')">
          <span
            class="txt--14px txt--color-04 txt--weight-600 txt--height-18px txt--height-20px--l"
          >
            {{ $t('header.logIn') }}
          </span>
        </exc-button>
      </div>
      <!-- user -->
      <exc-mob-nav-item
        v-else
        :only-theme="onlyTheme"
        class="rxd-header-mob__account-dropdown"
      >
        <template #name>
          <div>
            <component :is="userLinks.icon" />
          </div>
          <div class="rxd-header-mob__account-link-name">
            {{ userLinks.name }}
          </div>
          <div
            class="flx flx--items-center pl-28 exchange-header__status-wrapper"
            :class="userLinks.label.class"
          >
            <div class="exchange-header__kyc-icon mr-6" />
            <span
              class="exchange-header__kyc-text txt--weight-600 txt--12px txt--height-16px"
            >
              {{ userLinks.label.text }}
            </span>
          </div>
        </template>

        <template #dropdown>
          <exc-header-dropdown-item
            v-for="menuItem in userLinks.menu"
            :key="menuItem.name"
            v-bind="{
              ...menuItem,
              label: undefined,
              name: undefined,
              onlyTheme: onlyTheme,
            }"
            @click.native="removeLocale(menuItem.logout, toggleSidebar)"
          >
            <template #left>
              <!-- <component :is="menuItem.icon" /> -->
              <div class="menu-dropdown" />
            </template>
            <template #name>
              <span class="flx flx--items-center">
                {{ menuItem.name }}
                <exc-header-label-creator
                  v-if="menuItem.label"
                  :label="menuItem.label"
                  class="txt--16px"
                />
              </span>
            </template>
            <template v-if="menuItem.description" #description>
              {{ menuItem.description }}
            </template>
          </exc-header-dropdown-item>
        </template>
      </exc-mob-nav-item>

      <!-- TODO: notification -->
      <!-- <exc-mob-nav-item >
        <template #name>
          notification
        </template>
      </exc-mob-nav-item>-->
    </template>

    <!-- ////////////////////////////////// MOBILE CUSTOM PART -->
    <!-- <template #mobile-custom="{ toggleSidebar }"> -->
    <!-- show buttons for not logged in user -->
    <!-- <div
        v-if="!isLogined"
        class="exchange-header__auth-btns flx flx--items-center pl-14 pr-14 mt-8 mb-8"
        @click="toggleSidebar"
      >
        <exc-button
          size="xs"
          class="mr-10"
        >
          <span>
            <nuxt-link
              to="/register"
              class="txt--14px txt--color-01 txt--14px--l txt--weight-600 txt--height-18px txt--height-20px--l"
            >
              {{ $t('header.createAccount') }}
            </nuxt-link>
          </span>
        </exc-button>

        <exc-button
          size="xs"
          outline
          color="dark"
          :to="localePath('/login')"
        >
          <span
            class="txt--14px txt--color-04 txt--weight-600 txt--height-18px txt--height-20px--l"
          >
            {{ $t('header.logIn') }}
          </span>
        </exc-button>
      </div>
    </template> -->

    <template #header-mobile-right-part>
      <!-- Language-->
      <exc-header-nav-item class="exchange-header__lang-wrapper">
        <template #name>
          <span @click="$emit('select-language')">
            <img
              :src="require('@/assets/img/select-language.svg')"
              width="32px"
              height="32px"
              alt="Language"
            >
            {{
              MixinNavLangSwitch_currentLanguage &&
                MixinNavLangSwitch_currentLanguage.name
                ? MixinNavLangSwitch_currentLanguage.name
                : ''
            }}
          </span>
        </template>
      </exc-header-nav-item>
    </template>
  </exc-header>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import {
  ExcLink,
  ExcLabel,
  ExcHeader,
  ExcHeaderDeskNavCreator,
  ExcHeaderNavItem,
  ExcHeaderDropdownItem,
  ExcHeaderLabelCreator,
  ExcMobNavItem,
  ExcButton,
  ExcSwitch,
  ExcMobDropdownItem,
} from '@/components/common'
import { onlyThemePropValidator } from '@/lib/utils'
import MixinNavLangSwitch from '@/mixins/MixinNavLangSwitch'
import { KycStates } from '@/store/settings/verification'
// import {zendeskWidget} from '@/lib/zendeskWidget'

export default {
  name: 'ExchangeHeader',
  components: {
    ExcHeader,
    ExcSwitch,
    ExcHeaderDeskNavCreator,
    ExcHeaderNavItem,
    ExcHeaderDropdownItem,
    ExcHeaderLabelCreator,
    ExcMobNavItem,
    ExcMobDropdownItem,
    ExcLabel,
    ExcLink,
    ExcButton,

    TdxLogo: () =>
      import(/* webpackMode: "eager" */ 'assets/img/rxdLogo.svg?inline'),
    ExcLogo: () =>
      import(/* webpackMode: "eager" */ '@/assets/img/logo-full.svg?inline'),
  },
  mixins: [MixinNavLangSwitch],
  props: {
    onlyTheme: onlyThemePropValidator(),
    isTrade: { type: Boolean, default: false },
  },
  data () {
    return {
      chosenLanguage: 'English',
    }
  },
  computed: {
    ...mapGetters({
      kycState: 'settings/verification/kycState',
    }),
    ...mapState({
      userSettings: state => state.auth.user,
      isLogined: state => state.auth.loggedIn,
      isStatusDefault: state => state.settings.verification.isStatusDefault,
      //   isDarkThemeEnabled: state => state.darktheme.enabled,
    }),
    navigationLinks () {
      return [
        {
          name: this.$t('header.trade'),
          icon: require('@/icons/header/spot-trading.svg'),
          to: this.localePath('/exchange'),
        },
        // trade
        // {
        //   name: this.$t('header.trade'),
        //   icon: require('@/icons/header/Icon (6).svg'),
        //   // to: this.localePath('/exchange'),
        //   menu: [
        //     {
        //       name: this.$t('header.spotTrade'),
        //       icon: require('@/icons/header/spot-trading.svg?inline'),
        //       to: this.localePath('/exchange'),
        //     },
        //     {
        //       name: this.$t('header.demoTrade'),
        //       icon: require('@/icons/header/demo-trading.svg?inline'),
        //       to: this.localePath('/demo-exchange'),
        //     },
        //   ],
        // },
        {
          name: this.$t('header.products'),
          icon: require('@/icons/header/products.svg'),
          menu: [
            // {
            //   name: this.$t('header.launchpad.title'),
            //   icon: require('@/icons/header/ieo.svg?inline'),
            //   to: this.localePath('/launchpad'),
            // },
            {
              name: this.$t('header.earnSystem'),
              icon: require('@/icons/header/c-f.svg?inline'),
              to: this.localePath('/c-freezer'),
            },
            {
              name: this.$t('header.quickExchange'),
              icon: require('@/icons/header/qe.svg?inline'),
              to: this.localePath('/quick-exchange'),
            },
            // {
            //   name: this.$t('header.codes'),
            //   to: this.localePath('/codes'),
            // },
            // {
            //   name: 'OTC',
            //   to: this.localePath('/otc'),
            // },
            // {
            //   name: 'P2P',
            //   to: this.localePath('/p2p-loans'),
            // },
            {
              name: this.$t('header.buySellCrypto'),
              icon: require('@/icons/header/buy-sell-crypto.svg?inline'),
              to: this.localePath('/buy-sell-crypto'),
            },
            {
              name: this.$t('header.tradingCompetition.title'),
              icon: require('@/icons/header/trading-competition.svg?inline'),
              to: this.localePath('/trading-competition'),
            },
          ],
        },
        // {
        //   name: this.$t('header.airdrop'),
        //   label: {
        //     text: 'HOT',
        //   },
        //   icon: require('@/icons/header/airdrop.svg'),
        //   to: this.localePath('/airdrop'),
        // },
        {
          name: this.$t('header.listing'),
          icon: require('@/icons/header/listing.svg'),
          to: this.localePath('/listing'),
        },
        {
          name: this.$t('header.token'),
          icon: require('@/icons/header/token.svg'),
          to: this.localePath('/network'),
        },
        {
          name: this.$t('header.cryptoCards'),
          label: {
            text: 'HOT',
          },
          icon: require('@/icons/header/crypto-cards.svg'),
          to: this.localePath('/crypto-cards'),
        },
      ]
    },
    userLinks () {
      const verifiedLabel = (() => {
        // If the state is unknown, then no label
        if (this.isStatusDefault) return null

        return {
          text: this.kysStatus,
          class: this.statusClasses,
        }
      })()

      return {
        name: this.$t('header.account'),
        icon: require('@/icons/header/Icon (5).svg?inline'),
        label: verifiedLabel,
        menu: [
          {
            name: this.$t('header.profile'),
            to: this.localePath('/user/profile'),
            icon: require('@/icons/header/profile.svg?inline'),
          },
          {
            name: this.$t('header.security'),
            to: this.localePath('/user/security/two-factor-verification'),
            icon: require('@/icons/header/profile/security.svg?inline'),
          },
          {
            name: this.$t('header.ticket'),
            to: this.localePath('/user/ticket'),
            icon: require('@/icons/header/profile/ticket.svg?inline'),
          },
          {
            name: this.$t('header.wallet'),
            to: this.localePath('/user/wallet/wallet-list'),
            icon: require('@/icons/header/profile/wallet.svg?inline'),
          },
          {
            name: this.$t('header.claimSystem'),
            to: this.localePath('/claim-system'),
            icon: require('@/icons/header/profile/claim-system.svg?inline'),
          },
          {
            name: this.$t('header.referrals'),
            to: this.localePath('/user/referrals'),
            icon: require('@/icons/header/profile/referrals.svg?inline'),
          },
          {
            name: this.$t('header.api'),
            to: this.localePath('/user/api'),
            icon: require('@/icons/header/profile/api.svg?inline'),
          },
          {
            name: this.$t('header.history.title'),
            to: this.localePath('/user/history/deposit'),
            icon: require('@/icons/header/profile/history.svg?inline'),
          },
          {
            name: this.$t('header.logOut'),
            logout: true,
            icon: require('@/icons/header/profile/logOut.svg?inline'),
          },
        ],
      }
    },
    statusClasses () {
      switch (this.kycState) {
        // case KycStates.IN_PROGRESS:
        //   return 'status-in-progress'

        case KycStates.COMPLETED:
          return 'status-verified'

        // case KycStates.CANCELED:
        //   return 'status-unverified'
        default:
          return 'status-unverified'
      }
    },
    kysStatus () {
      switch (this.kycState) {
        // case KycStates.IN_PROGRESS:
        //   return this.$t('header.inProcess')

        case KycStates.COMPLETED:
          return this.$t('header.verified')

        // case KycStates.CANCELED:
        //   return this.$t('header.canceled')
        default:
          return this.$t('header.unverified')
      }
    },
  },
  methods: {
    ...mapMutations('darktheme', {
      themeToggle: 'toggle',
    }),
    onToggleMenu () {
      // this.$store.commit('ui/scrollToggle', !v)
    },
    // onClickZendesk() {
    //     zendeskWidget.open()
    // },
    async removeLocale (item, callback) {
      if (item) {
        await this.$store.dispatch('profile/auth/logout')
        await this.localePush('/')
      }

      if (callback) callback()
    },
  },
}
</script>

<style lang="scss" scoped>
.exchange-header {
  $root: &;
  background: color(color-01);
  box-shadow: 0px 0px 4px rgba(19, 26, 30, 0.04),
    0px 4px 16px rgba(29, 48, 69, 0.08);

  &__is-trade {
    box-shadow: unset;
  }

  &__logo {
    max-width: toRem(106px);
  }

  &__lang {
    display: grid;
    width: toRem(300px);
    grid-template-columns: 1fr 1fr;
  }

  &__kyc-icon {
    width: toRem(6px);
    height: toRem(6px);
    border-radius: 1000px;
  }

  &__status-wrapper {
    &.status-in-progress {
      .exchange-header__kyc-icon {
        background: color(orange);
      }

      .exchange-header__kyc-text {
        color: color(orange);
      }
    }

    &.status-verified {
      .exchange-header__kyc-icon {
        background: color(green);
      }

      .exchange-header__kyc-text {
        color: color(green);
      }
    }

    &.status-unverified {
      .exchange-header__kyc-icon {
        background: color(red);
      }

      .exchange-header__kyc-text {
        color: color(red);
      }
    }
  }
}

.rdx-header-desktop {
  &__user-item {
    width: toRem(143px);
  }
}

//::v-deep .exc-header-nav-item__name > span,
//::v-deep .exc-mob-nav-item__name .exc-label {
//  font-weight: 500;
//  font-size: toRem(16px);
//}

//::v-deep .exc-mob-nav-item__name {
//  span {
//    font-weight: 500;
//    font-size: 16px;
//  }
//}

::v-deep .exc-header-label-creator {
  &.bg--transp-007 {
    left: toRem(250px);
    background-color: #39952e;

    @include bp(320px, 375px) {
      left: toRem(255px);
    }
  }

  &.red {
    left: toRem(235px);
    background-color: #e64726;

    @include bp(320px, 375px) {
      left: toRem(240px);
    }
  }
}

::v-deep .rdx-header-desktop__account-logo {
  width: toRem(38px);
  height: toRem(38px);
  background: url('@/icons/user/white/Profile.svg') no-repeat center;
  background-size: 100% 100%;
  cursor: pointer;
}

::v-deep .exc-header-nav-item__name > span {
  cursor: pointer;
  color: $c-color-04;
}

::v-deep .exchange-header__auth-btns {
  a:active,
  a:focus {
    color: $c-color-01;
  }
}

::v-deep {
  .hamburger-inner,
  .hamburger-inner::after {
    top: toRem(8px);
    background-color: $c-color-04;
  }

  .hamburger-inner::before {
    top: toRem(-8px);
    background-color: $c-color-04;
  }

  .exc-header-mob__mob-nav-icon {
    margin-right: toRem(10px);
  }

  .exc-header-dropdown-item__name span {
    span {
      justify-content: space-between;

      span {
        max-width: fit-content;
      }
    }
  }
}

::v-deep .exchange-header__lang-wrapper {
  .exc-header-nav-item__name span span {
    display: flex;
    flex-direction: row;
    gap: toRem(6px);
    align-items: center;
  }
}

::v-deep .is-active {
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    display: none;
  }
}

::v-deep .rxd-header-mob__mob-close-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include bp(0, 1200px) {
    position: absolute;
    top: toRem(24px);
    right: toRem(14px);
  }
}

::v-deep .icon {
  width: toRem(24px);
  height: toRem(24px);
  background: url('@/icons/icon-close.svg') no-repeat center;
}

// ::v-deep.hamburger-box {
//   height: toRem(28px);
// }

::v-deep {
  .exc-header-mob {
    display: none;
  }
}

::v-deep {
  .exc-mob-dropdown-item__name-text {
    margin-left: toRem(38px);
  }
}

@include bp(0, 1200px) {
  ::v-deep {
    .exc-header-mob__mob-nav-container {
      min-width: max-content;
      padding-top: toRem(72px);
      padding-bottom: toRem(10px);
      position: relative;

      .rxd-header-mob__account-dropdown {
        .rxd-header-mob__account-link-name {
          display: flex;
          align-items: center;
          padding-left: toRem(10px);
        }

        .rxd-header-mob__account-link-label {
          position: relative;

          &::before {
            display: block;
            width: toRem(6px);
            height: toRem(6px);
            position: absolute;
            top: toRem(6px);
            left: toRem(16px);
            border-radius: toRem(56px);
            content: '';
          }

          &--red {
            &::before {
              background: color('red');
            }
          }

          &--green {
            &::before {
              background: color('green');
            }
          }
        }
      }
    }

    .exc-header-mob__mob-nav-icon {
      width: toRem(20px) !important;
      height: toRem(20px) !important;
    }

    .rxd-header-nav-item__arrow-container {
      margin-left: toRem(5px);
    }

    .exc-header-mob {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .exc-header-mob__mob-nav {
      width: toRem(350px);
    }

    .exchange-header__auth-btns {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr;
      grid-auto-rows: max-content;
      grid-gap: toRem(10px);

      .exc-button {
        width: 100%;
        white-space: nowrap;
      }
    }
  }
}

.menu-dropdown {
  width: 20px;
}
</style>
