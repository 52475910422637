// import moment from 'moment'
import { notifyError } from '@/lib/bus'
import { WalletPaymentMethod } from './WalletPaymentMethod'

export class WalletPaymentMethodDeposit extends WalletPaymentMethod {
  // constructor (o: IWalletPaymentMethodData) {
  //   super(o)
  // }

  getPayload (paymentKey) {
    let data

    switch (paymentKey) {
      // case WalletPaymentMethod.ids.LEOBANK:
      // case WalletPaymentMethod.ids.XPAY:
      //     const timeDifferent = Math.round(moment.duration(moment.utc().diff(moment.utc(moment().format('YYYY-MM-DD HH:mm:ss')))).asMinutes())
      //     data = {
      //         color_depth: screen.colorDepth,
      //         language: navigator.language,
      //         screen_height: screen.height,
      //         screen_width: screen.width,
      //         time_different: timeDifferent,
      //         window_width: window.innerWidth,
      //         window_height: window.innerHeight,
      //     }
      //     break

      default:
        data = {}
    }

    return data
  }

  async sendDepositForm ({ ticker, data = {}, method = 'GET', $api, isPixTranzzoMethod = false }) {
    const { response, errors } = await $api.sendDepositFormRequest({
      ticker,
      paymentKey: this.key,
      data: {
        ...data,
        ...this.getPayload(this.key),
      },
    })

    if (errors) {
      console.error('Errors sendDepositFormRequest', errors)
      return
    }

    const linkKey = 'redirectLink'
    if (!response[linkKey] && isPixTranzzoMethod) {
      return 'validators.deposit.incorrectPixNumber'
    }

    const form = this.createForm(response, method)
    form.submit()
  }

  createForm (response, method = 'GET') {
    const linkKey = 'redirectLink'
    const form = document.createElement('form') as HTMLFormElement
    form.method = method
    Object.keys(response).forEach((k) => {
      if (k === linkKey) return
      const input = document.createElement('input')
      input.name = k
      input.value = response[k]
      form.appendChild(input)
    })
    form.action = response[linkKey]
    form.classList.add('d-none')
    document.body.appendChild(form)

    return form
  }
}
