import { WalletPaymentMethod } from '@/lib/data-models/wallet/WalletPaymentMethod'

enum PaymentAction {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
}

export class WalletPaymentComponentMapper {
  private static readonly comps = {
    // DEPOSIT FORMS
    // DepositLeoBank: {
    //   name: 'WalletFormLeobankDeposit',
    // },
    DepositXpay: {
      name: 'WalletFormDepositXpay',
    },
    DepositCrypto: {
      name: 'WalletFormDepositCrypto',
    },
    DepositCryptoWithMemo: {
      name: 'WalletFormDepositCryptoWithMemo',
    },
    DepositFiatOneField: {
      name: 'WalletFormDepositFiatOneField',
    },
    DepositMercuryo: {
      name: 'WalletFormDepositMercuryo',
    },
    // DepositGrow: {
    //   name: 'WalletFormDepositGrow',
    // },
    DepositLatamex: {
      name: 'WalletFormDepositLatamex',
    },
    DepositPixTranzzo: {
      name: 'WalletFormDepositPixTranzzo',
    },
    // WITHDRAWAL FORMS
    WithdrawQiwiRub: {
      name: 'WalletFormWithdrawQiwiRub',
    },
    WithdrawCryptoWithMemo: {
      name: 'WalletFormWithdrawCryptoWithMemo',
    },
    WithdrawAdvAccount: {
      name: 'WalletFormWithdrawAdvAccount',
    },
    WithdrawCrypto: {
      name: 'WalletFormWithdrawCrypto',
    },
    WithdrawPixTranzzo: {
      name: 'WalletFormWithdrawPixTranzzo',
    },
    WithdrawVisaMaster: {
      name: 'WalletFormWithdrawVisaMaster',
    },
    WithdrawAddressAmount: {
      name: 'WalletFormWithdrawAddressAmount',
    },
    WithdrawMercuryo: {
      name: 'WalletFormWithdrawMercuryo',
    },
    // WithdrawLeobank: {
    //   name: 'WalletFormWithdrawLeobank',
    // },
    // WithdrawXpay: {
    //   name: 'WalletFormWithdrawXpay',
    // },
  }

  static mapPaymentToComponent (action: PaymentActionType, key: string) {
    return {
      [PaymentAction.DEPOSIT]: {
        // default only for crypto, (key as ticker),
        // for fiat backend dev said all keys will be specified
        [key]: WalletPaymentComponentMapper.comps.DepositCrypto.name,

        [WalletPaymentMethod.ids.CRYPTOMEMO]: WalletPaymentComponentMapper.comps.DepositCryptoWithMemo.name,
        [WalletPaymentMethod.ids.ADVACCOUNT]: WalletPaymentComponentMapper.comps.DepositFiatOneField.name,
        [WalletPaymentMethod.ids.ADVCARD]: WalletPaymentComponentMapper.comps.DepositFiatOneField.name,
        [WalletPaymentMethod.ids.PRRUB]: WalletPaymentComponentMapper.comps.DepositFiatOneField.name,
        [WalletPaymentMethod.ids.QWRUB]: WalletPaymentComponentMapper.comps.DepositFiatOneField.name,
        [WalletPaymentMethod.ids.YAMRUB]: WalletPaymentComponentMapper.comps.DepositFiatOneField.name,
        [WalletPaymentMethod.ids.WIRETRY]: WalletPaymentComponentMapper.comps.DepositFiatOneField.name,
        [WalletPaymentMethod.ids.P24UAH]: WalletPaymentComponentMapper.comps.DepositFiatOneField.name,
        [WalletPaymentMethod.ids.MONOBUAH]: WalletPaymentComponentMapper.comps.DepositFiatOneField.name,
        // [WalletPaymentMethod.ids.LEOBANK]: WalletPaymentComponentMapper.comps.DepositFiatOneField.name,
        // [WalletPaymentMethod.ids.LEOBANK_TERMINAL]: WalletPaymentComponentMapper.comps.DepositLeoBank.name,
        // [WalletPaymentMethod.ids.XPAY]: WalletPaymentComponentMapper.comps.DepositXpay.name,
        [WalletPaymentMethod.ids.LATAMEX]: WalletPaymentComponentMapper.comps.DepositLatamex.name,
        [WalletPaymentMethod.ids.PIXTRANZZO]: WalletPaymentComponentMapper.comps.DepositPixTranzzo.name,
        [WalletPaymentMethod.ids.MERCURYO]: WalletPaymentComponentMapper.comps.DepositMercuryo.name,
        // [WalletPaymentMethod.ids.GROW]: WalletPaymentComponentMapper.comps.DepositGrow.name,
      },
      [PaymentAction.WITHDRAWAL]: {
        // default only for crypto, (key as ticker),
        // for fiat backend dev said all keys will be specified
        [key]: WalletPaymentComponentMapper.comps.WithdrawCrypto.name,

        [WalletPaymentMethod.ids.PIXTRANZZO]: WalletPaymentComponentMapper.comps.WithdrawPixTranzzo.name,
        [WalletPaymentMethod.ids.CRYPTOMEMO]: WalletPaymentComponentMapper.comps.WithdrawCryptoWithMemo.name,
        [WalletPaymentMethod.ids.ADVACCOUNT]: WalletPaymentComponentMapper.comps.WithdrawAdvAccount.name,
        [WalletPaymentMethod.ids.ADVCARD]: WalletPaymentComponentMapper.comps.WithdrawVisaMaster.name,
        [WalletPaymentMethod.ids.PRRUB]: WalletPaymentComponentMapper.comps.WithdrawAdvAccount.name, // ?
        [WalletPaymentMethod.ids.QWRUB]: WalletPaymentComponentMapper.comps.WithdrawQiwiRub.name,
        [WalletPaymentMethod.ids.YAMRUB]: WalletPaymentComponentMapper.comps.WithdrawAddressAmount.name,
        [WalletPaymentMethod.ids.WIRETRY]: WalletPaymentComponentMapper.comps.WithdrawAddressAmount.name,
        [WalletPaymentMethod.ids.P24UAH]: WalletPaymentComponentMapper.comps.WithdrawAddressAmount.name,
        [WalletPaymentMethod.ids.MONOBUAH]: WalletPaymentComponentMapper.comps.WithdrawAddressAmount.name,
        [WalletPaymentMethod.ids.LATAMEX]: WalletPaymentComponentMapper.comps.WithdrawAddressAmount.name,
        // [WalletPaymentMethod.ids.LEOBANK]: WalletPaymentComponentMapper.comps.WithdrawLeobank.name,
        // [WalletPaymentMethod.ids.XPAY]: WalletPaymentComponentMapper.comps.WithdrawXpay.name,
        [WalletPaymentMethod.ids.MERCURYO]: WalletPaymentComponentMapper.comps.WithdrawMercuryo.name,
      },
    }[action][key]
  }
}
