import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3595fc96 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _01debcea = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5f2c483b = () => interopDefault(import('../pages/bug-bounty.vue' /* webpackChunkName: "pages/bug-bounty" */))
const _110698d2 = () => interopDefault(import('../pages/business.vue' /* webpackChunkName: "pages/business" */))
const _442a0cce = () => interopDefault(import('../pages/buy-sell-crypto.vue' /* webpackChunkName: "pages/buy-sell-crypto" */))
const _74de2de6 = () => interopDefault(import('../pages/c-freezer/index.vue' /* webpackChunkName: "pages/c-freezer/index" */))
const _42bc330c = () => interopDefault(import('../pages/claim-system/index.vue' /* webpackChunkName: "pages/claim-system/index" */))
const _6ecc7202 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _b7b7e2b8 = () => interopDefault(import('../pages/crypto-campus.vue' /* webpackChunkName: "pages/crypto-campus" */))
const _11dc302e = () => interopDefault(import('../pages/crypto-cards.vue' /* webpackChunkName: "pages/crypto-cards" */))
const _2388d3e2 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _c7fcdaf8 = () => interopDefault(import('../pages/fees.vue' /* webpackChunkName: "pages/fees" */))
const _e402d304 = () => interopDefault(import('../pages/latin-america.vue' /* webpackChunkName: "pages/latin-america" */))
const _75e25a7a = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _e0b27406 = () => interopDefault(import('../pages/listing.vue' /* webpackChunkName: "pages/listing" */))
const _6e8f76bc = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4937e518 = () => interopDefault(import('../pages/market-info.vue' /* webpackChunkName: "pages/market-info" */))
const _0eb8feb2 = () => interopDefault(import('../pages/network.vue' /* webpackChunkName: "pages/network" */))
const _1c872141 = () => interopDefault(import('../pages/not-found.vue' /* webpackChunkName: "pages/not-found" */))
const _694856d4 = () => interopDefault(import('../pages/privacy-notice.vue' /* webpackChunkName: "pages/privacy-notice" */))
const _13eba82e = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _6d6b95cc = () => interopDefault(import('../pages/quick-exchange.vue' /* webpackChunkName: "pages/quick-exchange" */))
const _8c680e0c = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _77ee0520 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _192d4a4f = () => interopDefault(import('../pages/risks-disclosure-statement.vue' /* webpackChunkName: "pages/risks-disclosure-statement" */))
const _8c08036a = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _5d5639c8 = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _6eaba86e = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))

const _7e8b61e2 = () => interopDefault(import('../pages/trading-competition/index.vue' /* webpackChunkName: "pages/trading-competition/index" */))
const _d2874cbc = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _3e1bef70 = () => interopDefault(import('../pages/user/api/index.vue' /* webpackChunkName: "pages/user/api/index" */))
const _2ec333ea = () => interopDefault(import('../pages/user/history/index.vue' /* webpackChunkName: "pages/user/history/index" */))
const _798a42bf = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _328ef28c = () => interopDefault(import('../pages/user/referrals/index.vue' /* webpackChunkName: "pages/user/referrals/index" */))
const _9b0962e8 = () => interopDefault(import('../pages/user/ticket/index.vue' /* webpackChunkName: "pages/user/ticket/index" */))
const _639a931b = () => interopDefault(import('../pages/user/verification/index.vue' /* webpackChunkName: "pages/user/verification/index" */))
const _0e6e55b2 = () => interopDefault(import('../pages/user/history/active-orders/index.vue' /* webpackChunkName: "pages/user/history/active-orders/index" */))
const _37813c8e = () => interopDefault(import('../pages/user/history/deposit/index.vue' /* webpackChunkName: "pages/user/history/deposit/index" */))
const _0444ee40 = () => interopDefault(import('../pages/user/history/orders/index.vue' /* webpackChunkName: "pages/user/history/orders/index" */))
const _73fc7a6a = () => interopDefault(import('../pages/user/history/trades/index.vue' /* webpackChunkName: "pages/user/history/trades/index" */))
const _fabe67b6 = () => interopDefault(import('../pages/user/history/withdraw/index.vue' /* webpackChunkName: "pages/user/history/withdraw/index" */))
const _dc27616e = () => interopDefault(import('../pages/user/security/change-password/index.vue' /* webpackChunkName: "pages/user/security/change-password/index" */))
const _8d8cabe6 = () => interopDefault(import('../pages/user/security/saved-addresses/index.vue' /* webpackChunkName: "pages/user/security/saved-addresses/index" */))
const _ddc1208a = () => interopDefault(import('../pages/user/security/security-sessions/index.vue' /* webpackChunkName: "pages/user/security/security-sessions/index" */))
const _520dc609 = () => interopDefault(import('../pages/user/security/two-factor-verification/index.vue' /* webpackChunkName: "pages/user/security/two-factor-verification/index" */))
const _4ebe639a = () => interopDefault(import('../pages/user/wallet/margin/index.vue' /* webpackChunkName: "pages/user/wallet/margin/index" */))
const _1500f648 = () => interopDefault(import('../pages/user/wallet/wallet-list/index.vue' /* webpackChunkName: "pages/user/wallet/wallet-list/index" */))
const _38fe6e5f = () => interopDefault(import('../pages/user/wallet/deposit/_ticker.vue' /* webpackChunkName: "pages/user/wallet/deposit/_ticker" */))
const _a9afa2e6 = () => interopDefault(import('../pages/user/wallet/withdraw/_ticker.vue' /* webpackChunkName: "pages/user/wallet/withdraw/_ticker" */))
const _ae5251e2 = () => interopDefault(import('../pages/c-freezer/history.vue' /* webpackChunkName: "pages/c-freezer/history" */))
const _2f604b58 = () => interopDefault(import('../pages/trading-competition/history/index.vue' /* webpackChunkName: "pages/trading-competition/history/index" */))
const _1954857c = () => interopDefault(import('../pages/c-freezer/item/_ticker.vue' /* webpackChunkName: "pages/c-freezer/item/_ticker" */))
const _6cb84e2e = () => interopDefault(import('../pages/c-freezer/plan/_id.vue' /* webpackChunkName: "pages/c-freezer/plan/_id" */))
const _543a07e4 = () => interopDefault(import('../pages/support/ticket/_id.vue' /* webpackChunkName: "pages/support/ticket/_id" */))
const _31031e0f = () => interopDefault(import('../pages/trading-competition/detail/_id.vue' /* webpackChunkName: "pages/trading-competition/detail/_id" */))
const _12dd9016 = () => interopDefault(import('../pages/trading-competition/plan/_ticker.vue' /* webpackChunkName: "pages/trading-competition/plan/_ticker" */))
const _35f0235b = () => interopDefault(import('../pages/markets/_id.vue' /* webpackChunkName: "pages/markets/_id" */))
const _0b6dec9f = () => interopDefault(import('../pages/referral/_id.vue' /* webpackChunkName: "pages/referral/_id" */))
const _25d2f621 = () => interopDefault(import('../pages/claim-system/_ticker/_fundId.vue' /* webpackChunkName: "pages/claim-system/_ticker/_fundId" */))
const _4825ecea = () => interopDefault(import('../pages/exchange/_left/_right.vue' /* webpackChunkName: "pages/exchange/_left/_right" */))
const _4e7b65c6 = () => interopDefault(import('../pages/trading-view/_left/_right.vue' /* webpackChunkName: "pages/trading-view/_left/_right" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _3595fc96,
    name: "about-us___en___default"
  }, {
    path: "/ar",
    component: _01debcea,
    name: "index___ar"
  }, {
    path: "/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___en___default"
  }, {
    path: "/business",
    component: _110698d2,
    name: "business___en___default"
  }, {
    path: "/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___en___default"
  }, {
    path: "/c-freezer",
    component: _74de2de6,
    name: "c-freezer___en___default"
  }, {
    path: "/claim-system",
    component: _42bc330c,
    name: "claim-system___en___default"
  }, {
    path: "/contact-us",
    component: _6ecc7202,
    name: "contact-us___en___default"
  }, {
    path: "/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___en___default"
  }, {
    path: "/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___en___default"
  }, {
    path: "/de",
    component: _01debcea,
    name: "index___de"
  }, {
    path: "/en",
    component: _01debcea,
    name: "index___en"
  }, {
    path: "/es",
    component: _01debcea,
    name: "index___es"
  }, {
    path: "/faq",
    component: _2388d3e2,
    name: "faq___en___default"
  }, {
    path: "/fees",
    component: _c7fcdaf8,
    name: "fees___en___default"
  }, {
    path: "/fl",
    component: _01debcea,
    name: "index___fl"
  }, {
    path: "/fr",
    component: _01debcea,
    name: "index___fr"
  }, {
    path: "/hi",
    component: _01debcea,
    name: "index___hi"
  }, {
    path: "/id",
    component: _01debcea,
    name: "index___id"
  }, {
    path: "/ko",
    component: _01debcea,
    name: "index___ko"
  }, {
    path: "/latin-america",
    component: _e402d304,
    name: "latin-america___en___default"
  }, {
    path: "/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___en___default"
  }, {
    path: "/listing",
    component: _e0b27406,
    name: "listing___en___default"
  }, {
    path: "/login",
    component: _6e8f76bc,
    name: "login___en___default"
  }, {
    path: "/market-info",
    component: _4937e518,
    name: "market-info___en___default"
  }, {
    path: "/network",
    component: _0eb8feb2,
    name: "network___en___default"
  }, {
    path: "/not-found",
    component: _1c872141,
    name: "not-found___en___default"
  }, {
    path: "/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___en___default"
  }, {
    path: "/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___en___default"
  }, {
    path: "/pt",
    component: _01debcea,
    name: "index___pt"
  }, {
    path: "/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___en___default"
  }, {
    path: "/register",
    component: _8c680e0c,
    name: "register___en___default"
  }, {
    path: "/reset-password",
    component: _77ee0520,
    name: "reset-password___en___default"
  }, {
    path: "/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___en___default"
  }, {
    path: "/ru",
    component: _01debcea,
    name: "index___ru"
  }, {
    path: "/sr",
    component: _01debcea,
    name: "index___sr"
  }, {
    path: "/support",
    component: _8c08036a,
    name: "support___en___default"
  }, {
    path: "/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___en___default"
  }, {
    path: "/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___en___default"
  }, {
    path: "/th",
    component: _01debcea,
    name: "index___th"
  }, {
    path: "/tr",
    component: _01debcea,
    name: "index___tr"
  }, {
    path: "/trade",
    redirect: "/exchange",
    name: "Trade"
  }, {
    path: "/trading",
    redirect: "/exchange",
    name: "Trading"
  }, {
    path: "/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___en___default"
  }, {
    path: "/ua",
    component: _01debcea,
    name: "index___ua"
  }, {
    path: "/user",
    component: _d2874cbc,
    name: "user___en___default",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___en___default"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___en___default"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___en___default"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___en___default"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___en___default"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___en___default"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___en___default"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___en___default"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___en___default"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___en___default"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___en___default"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___en___default"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___en___default"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___en___default"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___en___default"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___en___default"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___en___default"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___en___default"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___en___default"
    }]
  }, {
    path: "/vn",
    component: _01debcea,
    name: "index___vn"
  }, {
    path: "/zh",
    component: _01debcea,
    name: "index___zh"
  }, {
    path: "/ar/about-us",
    component: _3595fc96,
    name: "about-us___ar"
  }, {
    path: "/ar/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___ar"
  }, {
    path: "/ar/business",
    component: _110698d2,
    name: "business___ar"
  }, {
    path: "/ar/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___ar"
  }, {
    path: "/ar/c-freezer",
    component: _74de2de6,
    name: "c-freezer___ar"
  }, {
    path: "/ar/claim-system",
    component: _42bc330c,
    name: "claim-system___ar"
  }, {
    path: "/ar/contact-us",
    component: _6ecc7202,
    name: "contact-us___ar"
  }, {
    path: "/ar/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___ar"
  }, {
    path: "/ar/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___ar"
  }, {
    path: "/ar/faq",
    component: _2388d3e2,
    name: "faq___ar"
  }, {
    path: "/ar/fees",
    component: _c7fcdaf8,
    name: "fees___ar"
  }, {
    path: "/ar/latin-america",
    component: _e402d304,
    name: "latin-america___ar"
  }, {
    path: "/ar/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___ar"
  }, {
    path: "/ar/listing",
    component: _e0b27406,
    name: "listing___ar"
  }, {
    path: "/ar/login",
    component: _6e8f76bc,
    name: "login___ar"
  }, {
    path: "/ar/market-info",
    component: _4937e518,
    name: "market-info___ar"
  }, {
    path: "/ar/network",
    component: _0eb8feb2,
    name: "network___ar"
  }, {
    path: "/ar/not-found",
    component: _1c872141,
    name: "not-found___ar"
  }, {
    path: "/ar/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___ar"
  }, {
    path: "/ar/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___ar"
  }, {
    path: "/ar/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___ar"
  }, {
    path: "/ar/register",
    component: _8c680e0c,
    name: "register___ar"
  }, {
    path: "/ar/reset-password",
    component: _77ee0520,
    name: "reset-password___ar"
  }, {
    path: "/ar/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___ar"
  }, {
    path: "/ar/support",
    component: _8c08036a,
    name: "support___ar"
  }, {
    path: "/ar/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___ar"
  }, {
    path: "/ar/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___ar"
  }, {
    path: "/ar/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___ar"
  }, {
    path: "/ar/user",
    component: _d2874cbc,
    name: "user___ar",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___ar"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___ar"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___ar"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___ar"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___ar"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___ar"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___ar"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___ar"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___ar"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___ar"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___ar"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___ar"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___ar"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___ar"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___ar"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___ar"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___ar"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___ar"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___ar"
    }]
  }, {
    path: "/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___en___default"
  }, {
    path: "/de/about-us",
    component: _3595fc96,
    name: "about-us___de"
  }, {
    path: "/de/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___de"
  }, {
    path: "/de/business",
    component: _110698d2,
    name: "business___de"
  }, {
    path: "/de/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___de"
  }, {
    path: "/de/c-freezer",
    component: _74de2de6,
    name: "c-freezer___de"
  }, {
    path: "/de/claim-system",
    component: _42bc330c,
    name: "claim-system___de"
  }, {
    path: "/de/contact-us",
    component: _6ecc7202,
    name: "contact-us___de"
  }, {
    path: "/de/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___de"
  }, {
    path: "/de/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___de"
  }, {
    path: "/de/faq",
    component: _2388d3e2,
    name: "faq___de"
  }, {
    path: "/de/fees",
    component: _c7fcdaf8,
    name: "fees___de"
  }, {
    path: "/de/latin-america",
    component: _e402d304,
    name: "latin-america___de"
  }, {
    path: "/de/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___de"
  }, {
    path: "/de/listing",
    component: _e0b27406,
    name: "listing___de"
  }, {
    path: "/de/login",
    component: _6e8f76bc,
    name: "login___de"
  }, {
    path: "/de/market-info",
    component: _4937e518,
    name: "market-info___de"
  }, {
    path: "/de/network",
    component: _0eb8feb2,
    name: "network___de"
  }, {
    path: "/de/not-found",
    component: _1c872141,
    name: "not-found___de"
  }, {
    path: "/de/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___de"
  }, {
    path: "/de/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___de"
  }, {
    path: "/de/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___de"
  }, {
    path: "/de/register",
    component: _8c680e0c,
    name: "register___de"
  }, {
    path: "/de/reset-password",
    component: _77ee0520,
    name: "reset-password___de"
  }, {
    path: "/de/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___de"
  }, {
    path: "/de/support",
    component: _8c08036a,
    name: "support___de"
  }, {
    path: "/de/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___de"
  }, {
    path: "/de/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___de"
  }, {
    path: "/de/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___de"
  }, {
    path: "/de/user",
    component: _d2874cbc,
    name: "user___de",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___de"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___de"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___de"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___de"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___de"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___de"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___de"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___de"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___de"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___de"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___de"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___de"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___de"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___de"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___de"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___de"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___de"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___de"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___de"
    }]
  }, {
    path: "/en/about-us",
    component: _3595fc96,
    name: "about-us___en"
  }, {
    path: "/en/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___en"
  }, {
    path: "/en/business",
    component: _110698d2,
    name: "business___en"
  }, {
    path: "/en/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___en"
  }, {
    path: "/en/c-freezer",
    component: _74de2de6,
    name: "c-freezer___en"
  }, {
    path: "/en/claim-system",
    component: _42bc330c,
    name: "claim-system___en"
  }, {
    path: "/en/contact-us",
    component: _6ecc7202,
    name: "contact-us___en"
  }, {
    path: "/en/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___en"
  }, {
    path: "/en/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___en"
  }, {
    path: "/en/faq",
    component: _2388d3e2,
    name: "faq___en"
  }, {
    path: "/en/fees",
    component: _c7fcdaf8,
    name: "fees___en"
  }, {
    path: "/en/latin-america",
    component: _e402d304,
    name: "latin-america___en"
  }, {
    path: "/en/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___en"
  }, {
    path: "/en/listing",
    component: _e0b27406,
    name: "listing___en"
  }, {
    path: "/en/login",
    component: _6e8f76bc,
    name: "login___en"
  }, {
    path: "/en/market-info",
    component: _4937e518,
    name: "market-info___en"
  }, {
    path: "/en/network",
    component: _0eb8feb2,
    name: "network___en"
  }, {
    path: "/en/not-found",
    component: _1c872141,
    name: "not-found___en"
  }, {
    path: "/en/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___en"
  }, {
    path: "/en/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___en"
  }, {
    path: "/en/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___en"
  }, {
    path: "/en/register",
    component: _8c680e0c,
    name: "register___en"
  }, {
    path: "/en/reset-password",
    component: _77ee0520,
    name: "reset-password___en"
  }, {
    path: "/en/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___en"
  }, {
    path: "/en/support",
    component: _8c08036a,
    name: "support___en"
  }, {
    path: "/en/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___en"
  }, {
    path: "/en/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___en"
  }, {
    path: "/en/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___en"
  }, {
    path: "/en/user",
    component: _d2874cbc,
    name: "user___en",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___en"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___en"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___en"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___en"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___en"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___en"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___en"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___en"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___en"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___en"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___en"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___en"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___en"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___en"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___en"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___en"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___en"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___en"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___en"
    }]
  }, {
    path: "/es/about-us",
    component: _3595fc96,
    name: "about-us___es"
  }, {
    path: "/es/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___es"
  }, {
    path: "/es/business",
    component: _110698d2,
    name: "business___es"
  }, {
    path: "/es/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___es"
  }, {
    path: "/es/c-freezer",
    component: _74de2de6,
    name: "c-freezer___es"
  }, {
    path: "/es/claim-system",
    component: _42bc330c,
    name: "claim-system___es"
  }, {
    path: "/es/contact-us",
    component: _6ecc7202,
    name: "contact-us___es"
  }, {
    path: "/es/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___es"
  }, {
    path: "/es/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___es"
  }, {
    path: "/es/faq",
    component: _2388d3e2,
    name: "faq___es"
  }, {
    path: "/es/fees",
    component: _c7fcdaf8,
    name: "fees___es"
  }, {
    path: "/es/latin-america",
    component: _e402d304,
    name: "latin-america___es"
  }, {
    path: "/es/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___es"
  }, {
    path: "/es/listing",
    component: _e0b27406,
    name: "listing___es"
  }, {
    path: "/es/login",
    component: _6e8f76bc,
    name: "login___es"
  }, {
    path: "/es/market-info",
    component: _4937e518,
    name: "market-info___es"
  }, {
    path: "/es/network",
    component: _0eb8feb2,
    name: "network___es"
  }, {
    path: "/es/not-found",
    component: _1c872141,
    name: "not-found___es"
  }, {
    path: "/es/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___es"
  }, {
    path: "/es/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___es"
  }, {
    path: "/es/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___es"
  }, {
    path: "/es/register",
    component: _8c680e0c,
    name: "register___es"
  }, {
    path: "/es/reset-password",
    component: _77ee0520,
    name: "reset-password___es"
  }, {
    path: "/es/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___es"
  }, {
    path: "/es/support",
    component: _8c08036a,
    name: "support___es"
  }, {
    path: "/es/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___es"
  }, {
    path: "/es/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___es"
  }, {
    path: "/es/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___es"
  }, {
    path: "/es/user",
    component: _d2874cbc,
    name: "user___es",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___es"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___es"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___es"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___es"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___es"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___es"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___es"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___es"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___es"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___es"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___es"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___es"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___es"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___es"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___es"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___es"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___es"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___es"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___es"
    }]
  }, {
    path: "/fl/about-us",
    component: _3595fc96,
    name: "about-us___fl"
  }, {
    path: "/fl/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___fl"
  }, {
    path: "/fl/business",
    component: _110698d2,
    name: "business___fl"
  }, {
    path: "/fl/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___fl"
  }, {
    path: "/fl/c-freezer",
    component: _74de2de6,
    name: "c-freezer___fl"
  }, {
    path: "/fl/claim-system",
    component: _42bc330c,
    name: "claim-system___fl"
  }, {
    path: "/fl/contact-us",
    component: _6ecc7202,
    name: "contact-us___fl"
  }, {
    path: "/fl/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___fl"
  }, {
    path: "/fl/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___fl"
  }, {
    path: "/fl/faq",
    component: _2388d3e2,
    name: "faq___fl"
  }, {
    path: "/fl/fees",
    component: _c7fcdaf8,
    name: "fees___fl"
  }, {
    path: "/fl/latin-america",
    component: _e402d304,
    name: "latin-america___fl"
  }, {
    path: "/fl/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___fl"
  }, {
    path: "/fl/listing",
    component: _e0b27406,
    name: "listing___fl"
  }, {
    path: "/fl/login",
    component: _6e8f76bc,
    name: "login___fl"
  }, {
    path: "/fl/market-info",
    component: _4937e518,
    name: "market-info___fl"
  }, {
    path: "/fl/network",
    component: _0eb8feb2,
    name: "network___fl"
  }, {
    path: "/fl/not-found",
    component: _1c872141,
    name: "not-found___fl"
  }, {
    path: "/fl/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___fl"
  }, {
    path: "/fl/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___fl"
  }, {
    path: "/fl/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___fl"
  }, {
    path: "/fl/register",
    component: _8c680e0c,
    name: "register___fl"
  }, {
    path: "/fl/reset-password",
    component: _77ee0520,
    name: "reset-password___fl"
  }, {
    path: "/fl/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___fl"
  }, {
    path: "/fl/support",
    component: _8c08036a,
    name: "support___fl"
  }, {
    path: "/fl/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___fl"
  }, {
    path: "/fl/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___fl"
  }, {
    path: "/fl/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___fl"
  }, {
    path: "/fl/user",
    component: _d2874cbc,
    name: "user___fl",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___fl"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___fl"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___fl"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___fl"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___fl"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___fl"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___fl"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___fl"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___fl"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___fl"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___fl"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___fl"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___fl"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___fl"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___fl"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___fl"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___fl"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___fl"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___fl"
    }]
  }, {
    path: "/fr/about-us",
    component: _3595fc96,
    name: "about-us___fr"
  }, {
    path: "/fr/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___fr"
  }, {
    path: "/fr/business",
    component: _110698d2,
    name: "business___fr"
  }, {
    path: "/fr/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___fr"
  }, {
    path: "/fr/c-freezer",
    component: _74de2de6,
    name: "c-freezer___fr"
  }, {
    path: "/fr/claim-system",
    component: _42bc330c,
    name: "claim-system___fr"
  }, {
    path: "/fr/contact-us",
    component: _6ecc7202,
    name: "contact-us___fr"
  }, {
    path: "/fr/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___fr"
  }, {
    path: "/fr/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___fr"
  }, {
    path: "/fr/faq",
    component: _2388d3e2,
    name: "faq___fr"
  }, {
    path: "/fr/fees",
    component: _c7fcdaf8,
    name: "fees___fr"
  }, {
    path: "/fr/latin-america",
    component: _e402d304,
    name: "latin-america___fr"
  }, {
    path: "/fr/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___fr"
  }, {
    path: "/fr/listing",
    component: _e0b27406,
    name: "listing___fr"
  }, {
    path: "/fr/login",
    component: _6e8f76bc,
    name: "login___fr"
  }, {
    path: "/fr/market-info",
    component: _4937e518,
    name: "market-info___fr"
  }, {
    path: "/fr/network",
    component: _0eb8feb2,
    name: "network___fr"
  }, {
    path: "/fr/not-found",
    component: _1c872141,
    name: "not-found___fr"
  }, {
    path: "/fr/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___fr"
  }, {
    path: "/fr/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___fr"
  }, {
    path: "/fr/register",
    component: _8c680e0c,
    name: "register___fr"
  }, {
    path: "/fr/reset-password",
    component: _77ee0520,
    name: "reset-password___fr"
  }, {
    path: "/fr/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___fr"
  }, {
    path: "/fr/support",
    component: _8c08036a,
    name: "support___fr"
  }, {
    path: "/fr/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___fr"
  }, {
    path: "/fr/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___fr"
  }, {
    path: "/fr/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___fr"
  }, {
    path: "/fr/user",
    component: _d2874cbc,
    name: "user___fr",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___fr"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___fr"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___fr"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___fr"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___fr"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___fr"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___fr"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___fr"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___fr"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___fr"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___fr"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___fr"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___fr"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___fr"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___fr"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___fr"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___fr"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___fr"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___fr"
    }]
  }, {
    path: "/hi/about-us",
    component: _3595fc96,
    name: "about-us___hi"
  }, {
    path: "/hi/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___hi"
  }, {
    path: "/hi/business",
    component: _110698d2,
    name: "business___hi"
  }, {
    path: "/hi/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___hi"
  }, {
    path: "/hi/c-freezer",
    component: _74de2de6,
    name: "c-freezer___hi"
  }, {
    path: "/hi/claim-system",
    component: _42bc330c,
    name: "claim-system___hi"
  }, {
    path: "/hi/contact-us",
    component: _6ecc7202,
    name: "contact-us___hi"
  }, {
    path: "/hi/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___hi"
  }, {
    path: "/hi/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___hi"
  }, {
    path: "/hi/faq",
    component: _2388d3e2,
    name: "faq___hi"
  }, {
    path: "/hi/fees",
    component: _c7fcdaf8,
    name: "fees___hi"
  }, {
    path: "/hi/latin-america",
    component: _e402d304,
    name: "latin-america___hi"
  }, {
    path: "/hi/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___hi"
  }, {
    path: "/hi/listing",
    component: _e0b27406,
    name: "listing___hi"
  }, {
    path: "/hi/login",
    component: _6e8f76bc,
    name: "login___hi"
  }, {
    path: "/hi/market-info",
    component: _4937e518,
    name: "market-info___hi"
  }, {
    path: "/hi/network",
    component: _0eb8feb2,
    name: "network___hi"
  }, {
    path: "/hi/not-found",
    component: _1c872141,
    name: "not-found___hi"
  }, {
    path: "/hi/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___hi"
  }, {
    path: "/hi/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___hi"
  }, {
    path: "/hi/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___hi"
  }, {
    path: "/hi/register",
    component: _8c680e0c,
    name: "register___hi"
  }, {
    path: "/hi/reset-password",
    component: _77ee0520,
    name: "reset-password___hi"
  }, {
    path: "/hi/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___hi"
  }, {
    path: "/hi/support",
    component: _8c08036a,
    name: "support___hi"
  }, {
    path: "/hi/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___hi"
  }, {
    path: "/hi/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___hi"
  }, {
    path: "/hi/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___hi"
  }, {
    path: "/hi/user",
    component: _d2874cbc,
    name: "user___hi",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___hi"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___hi"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___hi"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___hi"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___hi"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___hi"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___hi"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___hi"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___hi"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___hi"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___hi"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___hi"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___hi"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___hi"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___hi"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___hi"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___hi"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___hi"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___hi"
    }]
  }, {
    path: "/id/about-us",
    component: _3595fc96,
    name: "about-us___id"
  }, {
    path: "/id/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___id"
  }, {
    path: "/id/business",
    component: _110698d2,
    name: "business___id"
  }, {
    path: "/id/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___id"
  }, {
    path: "/id/c-freezer",
    component: _74de2de6,
    name: "c-freezer___id"
  }, {
    path: "/id/claim-system",
    component: _42bc330c,
    name: "claim-system___id"
  }, {
    path: "/id/contact-us",
    component: _6ecc7202,
    name: "contact-us___id"
  }, {
    path: "/id/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___id"
  }, {
    path: "/id/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___id"
  }, {
    path: "/id/faq",
    component: _2388d3e2,
    name: "faq___id"
  }, {
    path: "/id/fees",
    component: _c7fcdaf8,
    name: "fees___id"
  }, {
    path: "/id/latin-america",
    component: _e402d304,
    name: "latin-america___id"
  }, {
    path: "/id/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___id"
  }, {
    path: "/id/listing",
    component: _e0b27406,
    name: "listing___id"
  }, {
    path: "/id/login",
    component: _6e8f76bc,
    name: "login___id"
  }, {
    path: "/id/market-info",
    component: _4937e518,
    name: "market-info___id"
  }, {
    path: "/id/network",
    component: _0eb8feb2,
    name: "network___id"
  }, {
    path: "/id/not-found",
    component: _1c872141,
    name: "not-found___id"
  }, {
    path: "/id/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___id"
  }, {
    path: "/id/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___id"
  }, {
    path: "/id/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___id"
  }, {
    path: "/id/register",
    component: _8c680e0c,
    name: "register___id"
  }, {
    path: "/id/reset-password",
    component: _77ee0520,
    name: "reset-password___id"
  }, {
    path: "/id/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___id"
  }, {
    path: "/id/support",
    component: _8c08036a,
    name: "support___id"
  }, {
    path: "/id/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___id"
  }, {
    path: "/id/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___id"
  }, {
    path: "/id/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___id"
  }, {
    path: "/id/user",
    component: _d2874cbc,
    name: "user___id",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___id"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___id"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___id"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___id"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___id"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___id"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___id"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___id"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___id"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___id"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___id"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___id"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___id"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___id"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___id"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___id"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___id"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___id"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___id"
    }]
  }, {
    path: "/ko/about-us",
    component: _3595fc96,
    name: "about-us___ko"
  }, {
    path: "/ko/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___ko"
  }, {
    path: "/ko/business",
    component: _110698d2,
    name: "business___ko"
  }, {
    path: "/ko/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___ko"
  }, {
    path: "/ko/c-freezer",
    component: _74de2de6,
    name: "c-freezer___ko"
  }, {
    path: "/ko/claim-system",
    component: _42bc330c,
    name: "claim-system___ko"
  }, {
    path: "/ko/contact-us",
    component: _6ecc7202,
    name: "contact-us___ko"
  }, {
    path: "/ko/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___ko"
  }, {
    path: "/ko/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___ko"
  }, {
    path: "/ko/faq",
    component: _2388d3e2,
    name: "faq___ko"
  }, {
    path: "/ko/fees",
    component: _c7fcdaf8,
    name: "fees___ko"
  }, {
    path: "/ko/latin-america",
    component: _e402d304,
    name: "latin-america___ko"
  }, {
    path: "/ko/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___ko"
  }, {
    path: "/ko/listing",
    component: _e0b27406,
    name: "listing___ko"
  }, {
    path: "/ko/login",
    component: _6e8f76bc,
    name: "login___ko"
  }, {
    path: "/ko/market-info",
    component: _4937e518,
    name: "market-info___ko"
  }, {
    path: "/ko/network",
    component: _0eb8feb2,
    name: "network___ko"
  }, {
    path: "/ko/not-found",
    component: _1c872141,
    name: "not-found___ko"
  }, {
    path: "/ko/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___ko"
  }, {
    path: "/ko/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___ko"
  }, {
    path: "/ko/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___ko"
  }, {
    path: "/ko/register",
    component: _8c680e0c,
    name: "register___ko"
  }, {
    path: "/ko/reset-password",
    component: _77ee0520,
    name: "reset-password___ko"
  }, {
    path: "/ko/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___ko"
  }, {
    path: "/ko/support",
    component: _8c08036a,
    name: "support___ko"
  }, {
    path: "/ko/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___ko"
  }, {
    path: "/ko/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___ko"
  }, {
    path: "/ko/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___ko"
  }, {
    path: "/ko/user",
    component: _d2874cbc,
    name: "user___ko",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___ko"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___ko"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___ko"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___ko"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___ko"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___ko"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___ko"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___ko"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___ko"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___ko"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___ko"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___ko"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___ko"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___ko"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___ko"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___ko"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___ko"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___ko"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___ko"
    }]
  }, {
    path: "/pt/about-us",
    component: _3595fc96,
    name: "about-us___pt"
  }, {
    path: "/pt/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___pt"
  }, {
    path: "/pt/business",
    component: _110698d2,
    name: "business___pt"
  }, {
    path: "/pt/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___pt"
  }, {
    path: "/pt/c-freezer",
    component: _74de2de6,
    name: "c-freezer___pt"
  }, {
    path: "/pt/claim-system",
    component: _42bc330c,
    name: "claim-system___pt"
  }, {
    path: "/pt/contact-us",
    component: _6ecc7202,
    name: "contact-us___pt"
  }, {
    path: "/pt/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___pt"
  }, {
    path: "/pt/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___pt"
  }, {
    path: "/pt/faq",
    component: _2388d3e2,
    name: "faq___pt"
  }, {
    path: "/pt/fees",
    component: _c7fcdaf8,
    name: "fees___pt"
  }, {
    path: "/pt/latin-america",
    component: _e402d304,
    name: "latin-america___pt"
  }, {
    path: "/pt/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___pt"
  }, {
    path: "/pt/listing",
    component: _e0b27406,
    name: "listing___pt"
  }, {
    path: "/pt/login",
    component: _6e8f76bc,
    name: "login___pt"
  }, {
    path: "/pt/market-info",
    component: _4937e518,
    name: "market-info___pt"
  }, {
    path: "/pt/network",
    component: _0eb8feb2,
    name: "network___pt"
  }, {
    path: "/pt/not-found",
    component: _1c872141,
    name: "not-found___pt"
  }, {
    path: "/pt/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___pt"
  }, {
    path: "/pt/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___pt"
  }, {
    path: "/pt/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___pt"
  }, {
    path: "/pt/register",
    component: _8c680e0c,
    name: "register___pt"
  }, {
    path: "/pt/reset-password",
    component: _77ee0520,
    name: "reset-password___pt"
  }, {
    path: "/pt/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___pt"
  }, {
    path: "/pt/support",
    component: _8c08036a,
    name: "support___pt"
  }, {
    path: "/pt/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___pt"
  }, {
    path: "/pt/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___pt"
  }, {
    path: "/pt/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___pt"
  }, {
    path: "/pt/user",
    component: _d2874cbc,
    name: "user___pt",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___pt"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___pt"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___pt"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___pt"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___pt"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___pt"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___pt"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___pt"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___pt"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___pt"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___pt"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___pt"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___pt"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___pt"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___pt"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___pt"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___pt"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___pt"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___pt"
    }]
  }, {
    path: "/ru/about-us",
    component: _3595fc96,
    name: "about-us___ru"
  }, {
    path: "/ru/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___ru"
  }, {
    path: "/ru/business",
    component: _110698d2,
    name: "business___ru"
  }, {
    path: "/ru/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___ru"
  }, {
    path: "/ru/c-freezer",
    component: _74de2de6,
    name: "c-freezer___ru"
  }, {
    path: "/ru/claim-system",
    component: _42bc330c,
    name: "claim-system___ru"
  }, {
    path: "/ru/contact-us",
    component: _6ecc7202,
    name: "contact-us___ru"
  }, {
    path: "/ru/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___ru"
  }, {
    path: "/ru/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___ru"
  }, {
    path: "/ru/faq",
    component: _2388d3e2,
    name: "faq___ru"
  }, {
    path: "/ru/fees",
    component: _c7fcdaf8,
    name: "fees___ru"
  }, {
    path: "/ru/latin-america",
    component: _e402d304,
    name: "latin-america___ru"
  }, {
    path: "/ru/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___ru"
  }, {
    path: "/ru/listing",
    component: _e0b27406,
    name: "listing___ru"
  }, {
    path: "/ru/login",
    component: _6e8f76bc,
    name: "login___ru"
  }, {
    path: "/ru/market-info",
    component: _4937e518,
    name: "market-info___ru"
  }, {
    path: "/ru/network",
    component: _0eb8feb2,
    name: "network___ru"
  }, {
    path: "/ru/not-found",
    component: _1c872141,
    name: "not-found___ru"
  }, {
    path: "/ru/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___ru"
  }, {
    path: "/ru/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___ru"
  }, {
    path: "/ru/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___ru"
  }, {
    path: "/ru/register",
    component: _8c680e0c,
    name: "register___ru"
  }, {
    path: "/ru/reset-password",
    component: _77ee0520,
    name: "reset-password___ru"
  }, {
    path: "/ru/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___ru"
  }, {
    path: "/ru/support",
    component: _8c08036a,
    name: "support___ru"
  }, {
    path: "/ru/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___ru"
  }, {
    path: "/ru/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___ru"
  }, {
    path: "/ru/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___ru"
  }, {
    path: "/ru/user",
    component: _d2874cbc,
    name: "user___ru",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___ru"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___ru"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___ru"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___ru"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___ru"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___ru"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___ru"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___ru"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___ru"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___ru"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___ru"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___ru"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___ru"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___ru"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___ru"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___ru"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___ru"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___ru"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___ru"
    }]
  }, {
    path: "/sr/about-us",
    component: _3595fc96,
    name: "about-us___sr"
  }, {
    path: "/sr/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___sr"
  }, {
    path: "/sr/business",
    component: _110698d2,
    name: "business___sr"
  }, {
    path: "/sr/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___sr"
  }, {
    path: "/sr/c-freezer",
    component: _74de2de6,
    name: "c-freezer___sr"
  }, {
    path: "/sr/claim-system",
    component: _42bc330c,
    name: "claim-system___sr"
  }, {
    path: "/sr/contact-us",
    component: _6ecc7202,
    name: "contact-us___sr"
  }, {
    path: "/sr/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___sr"
  }, {
    path: "/sr/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___sr"
  }, {
    path: "/sr/faq",
    component: _2388d3e2,
    name: "faq___sr"
  }, {
    path: "/sr/fees",
    component: _c7fcdaf8,
    name: "fees___sr"
  }, {
    path: "/sr/latin-america",
    component: _e402d304,
    name: "latin-america___sr"
  }, {
    path: "/sr/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___sr"
  }, {
    path: "/sr/listing",
    component: _e0b27406,
    name: "listing___sr"
  }, {
    path: "/sr/login",
    component: _6e8f76bc,
    name: "login___sr"
  }, {
    path: "/sr/market-info",
    component: _4937e518,
    name: "market-info___sr"
  }, {
    path: "/sr/network",
    component: _0eb8feb2,
    name: "network___sr"
  }, {
    path: "/sr/not-found",
    component: _1c872141,
    name: "not-found___sr"
  }, {
    path: "/sr/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___sr"
  }, {
    path: "/sr/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___sr"
  }, {
    path: "/sr/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___sr"
  }, {
    path: "/sr/register",
    component: _8c680e0c,
    name: "register___sr"
  }, {
    path: "/sr/reset-password",
    component: _77ee0520,
    name: "reset-password___sr"
  }, {
    path: "/sr/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___sr"
  }, {
    path: "/sr/support",
    component: _8c08036a,
    name: "support___sr"
  }, {
    path: "/sr/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___sr"
  }, {
    path: "/sr/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___sr"
  }, {
    path: "/sr/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___sr"
  }, {
    path: "/sr/user",
    component: _d2874cbc,
    name: "user___sr",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___sr"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___sr"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___sr"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___sr"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___sr"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___sr"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___sr"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___sr"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___sr"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___sr"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___sr"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___sr"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___sr"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___sr"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___sr"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___sr"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___sr"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___sr"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___sr"
    }]
  }, {
    path: "/th/about-us",
    component: _3595fc96,
    name: "about-us___th"
  }, {
    path: "/th/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___th"
  }, {
    path: "/th/business",
    component: _110698d2,
    name: "business___th"
  }, {
    path: "/th/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___th"
  }, {
    path: "/th/c-freezer",
    component: _74de2de6,
    name: "c-freezer___th"
  }, {
    path: "/th/claim-system",
    component: _42bc330c,
    name: "claim-system___th"
  }, {
    path: "/th/contact-us",
    component: _6ecc7202,
    name: "contact-us___th"
  }, {
    path: "/th/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___th"
  }, {
    path: "/th/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___th"
  }, {
    path: "/th/faq",
    component: _2388d3e2,
    name: "faq___th"
  }, {
    path: "/th/fees",
    component: _c7fcdaf8,
    name: "fees___th"
  }, {
    path: "/th/latin-america",
    component: _e402d304,
    name: "latin-america___th"
  }, {
    path: "/th/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___th"
  }, {
    path: "/th/listing",
    component: _e0b27406,
    name: "listing___th"
  }, {
    path: "/th/login",
    component: _6e8f76bc,
    name: "login___th"
  }, {
    path: "/th/market-info",
    component: _4937e518,
    name: "market-info___th"
  }, {
    path: "/th/network",
    component: _0eb8feb2,
    name: "network___th"
  }, {
    path: "/th/not-found",
    component: _1c872141,
    name: "not-found___th"
  }, {
    path: "/th/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___th"
  }, {
    path: "/th/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___th"
  }, {
    path: "/th/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___th"
  }, {
    path: "/th/register",
    component: _8c680e0c,
    name: "register___th"
  }, {
    path: "/th/reset-password",
    component: _77ee0520,
    name: "reset-password___th"
  }, {
    path: "/th/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___th"
  }, {
    path: "/th/support",
    component: _8c08036a,
    name: "support___th"
  }, {
    path: "/th/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___th"
  }, {
    path: "/th/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___th"
  }, {
    path: "/th/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___th"
  }, {
    path: "/th/user",
    component: _d2874cbc,
    name: "user___th",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___th"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___th"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___th"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___th"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___th"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___th"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___th"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___th"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___th"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___th"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___th"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___th"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___th"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___th"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___th"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___th"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___th"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___th"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___th"
    }]
  }, {
    path: "/tr/about-us",
    component: _3595fc96,
    name: "about-us___tr"
  }, {
    path: "/tr/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___tr"
  }, {
    path: "/tr/business",
    component: _110698d2,
    name: "business___tr"
  }, {
    path: "/tr/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___tr"
  }, {
    path: "/tr/c-freezer",
    component: _74de2de6,
    name: "c-freezer___tr"
  }, {
    path: "/tr/claim-system",
    component: _42bc330c,
    name: "claim-system___tr"
  }, {
    path: "/tr/contact-us",
    component: _6ecc7202,
    name: "contact-us___tr"
  }, {
    path: "/tr/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___tr"
  }, {
    path: "/tr/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___tr"
  }, {
    path: "/tr/faq",
    component: _2388d3e2,
    name: "faq___tr"
  }, {
    path: "/tr/fees",
    component: _c7fcdaf8,
    name: "fees___tr"
  }, {
    path: "/tr/latin-america",
    component: _e402d304,
    name: "latin-america___tr"
  }, {
    path: "/tr/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___tr"
  }, {
    path: "/tr/listing",
    component: _e0b27406,
    name: "listing___tr"
  }, {
    path: "/tr/login",
    component: _6e8f76bc,
    name: "login___tr"
  }, {
    path: "/tr/market-info",
    component: _4937e518,
    name: "market-info___tr"
  }, {
    path: "/tr/network",
    component: _0eb8feb2,
    name: "network___tr"
  }, {
    path: "/tr/not-found",
    component: _1c872141,
    name: "not-found___tr"
  }, {
    path: "/tr/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___tr"
  }, {
    path: "/tr/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___tr"
  }, {
    path: "/tr/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___tr"
  }, {
    path: "/tr/register",
    component: _8c680e0c,
    name: "register___tr"
  }, {
    path: "/tr/reset-password",
    component: _77ee0520,
    name: "reset-password___tr"
  }, {
    path: "/tr/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___tr"
  }, {
    path: "/tr/support",
    component: _8c08036a,
    name: "support___tr"
  }, {
    path: "/tr/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___tr"
  }, {
    path: "/tr/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___tr"
  }, {
    path: "/tr/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___tr"
  }, {
    path: "/tr/user",
    component: _d2874cbc,
    name: "user___tr",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___tr"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___tr"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___tr"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___tr"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___tr"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___tr"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___tr"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___tr"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___tr"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___tr"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___tr"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___tr"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___tr"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___tr"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___tr"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___tr"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___tr"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___tr"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___tr"
    }]
  }, {
    path: "/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___en___default"
  }, {
    path: "/ua/about-us",
    component: _3595fc96,
    name: "about-us___ua"
  }, {
    path: "/ua/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___ua"
  }, {
    path: "/ua/business",
    component: _110698d2,
    name: "business___ua"
  }, {
    path: "/ua/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___ua"
  }, {
    path: "/ua/c-freezer",
    component: _74de2de6,
    name: "c-freezer___ua"
  }, {
    path: "/ua/claim-system",
    component: _42bc330c,
    name: "claim-system___ua"
  }, {
    path: "/ua/contact-us",
    component: _6ecc7202,
    name: "contact-us___ua"
  }, {
    path: "/ua/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___ua"
  }, {
    path: "/ua/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___ua"
  }, {
    path: "/ua/faq",
    component: _2388d3e2,
    name: "faq___ua"
  }, {
    path: "/ua/fees",
    component: _c7fcdaf8,
    name: "fees___ua"
  }, {
    path: "/ua/latin-america",
    component: _e402d304,
    name: "latin-america___ua"
  }, {
    path: "/ua/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___ua"
  }, {
    path: "/ua/listing",
    component: _e0b27406,
    name: "listing___ua"
  }, {
    path: "/ua/login",
    component: _6e8f76bc,
    name: "login___ua"
  }, {
    path: "/ua/market-info",
    component: _4937e518,
    name: "market-info___ua"
  }, {
    path: "/ua/network",
    component: _0eb8feb2,
    name: "network___ua"
  }, {
    path: "/ua/not-found",
    component: _1c872141,
    name: "not-found___ua"
  }, {
    path: "/ua/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___ua"
  }, {
    path: "/ua/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___ua"
  }, {
    path: "/ua/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___ua"
  }, {
    path: "/ua/register",
    component: _8c680e0c,
    name: "register___ua"
  }, {
    path: "/ua/reset-password",
    component: _77ee0520,
    name: "reset-password___ua"
  }, {
    path: "/ua/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___ua"
  }, {
    path: "/ua/support",
    component: _8c08036a,
    name: "support___ua"
  }, {
    path: "/ua/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___ua"
  }, {
    path: "/ua/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___ua"
  }, {
    path: "/ua/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___ua"
  }, {
    path: "/ua/user",
    component: _d2874cbc,
    name: "user___ua",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___ua"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___ua"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___ua"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___ua"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___ua"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___ua"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___ua"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___ua"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___ua"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___ua"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___ua"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___ua"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___ua"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___ua"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___ua"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___ua"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___ua"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___ua"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___ua"
    }]
  }, {
    path: "/vn/about-us",
    component: _3595fc96,
    name: "about-us___vn"
  }, {
    path: "/vn/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___vn"
  }, {
    path: "/vn/business",
    component: _110698d2,
    name: "business___vn"
  }, {
    path: "/vn/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___vn"
  }, {
    path: "/vn/c-freezer",
    component: _74de2de6,
    name: "c-freezer___vn"
  }, {
    path: "/vn/claim-system",
    component: _42bc330c,
    name: "claim-system___vn"
  }, {
    path: "/vn/contact-us",
    component: _6ecc7202,
    name: "contact-us___vn"
  }, {
    path: "/vn/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___vn"
  }, {
    path: "/vn/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___vn"
  }, {
    path: "/vn/faq",
    component: _2388d3e2,
    name: "faq___vn"
  }, {
    path: "/vn/fees",
    component: _c7fcdaf8,
    name: "fees___vn"
  }, {
    path: "/vn/latin-america",
    component: _e402d304,
    name: "latin-america___vn"
  }, {
    path: "/vn/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___vn"
  }, {
    path: "/vn/listing",
    component: _e0b27406,
    name: "listing___vn"
  }, {
    path: "/vn/login",
    component: _6e8f76bc,
    name: "login___vn"
  }, {
    path: "/vn/market-info",
    component: _4937e518,
    name: "market-info___vn"
  }, {
    path: "/vn/network",
    component: _0eb8feb2,
    name: "network___vn"
  }, {
    path: "/vn/not-found",
    component: _1c872141,
    name: "not-found___vn"
  }, {
    path: "/vn/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___vn"
  }, {
    path: "/vn/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___vn"
  }, {
    path: "/vn/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___vn"
  }, {
    path: "/vn/register",
    component: _8c680e0c,
    name: "register___vn"
  }, {
    path: "/vn/reset-password",
    component: _77ee0520,
    name: "reset-password___vn"
  }, {
    path: "/vn/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___vn"
  }, {
    path: "/vn/support",
    component: _8c08036a,
    name: "support___vn"
  }, {
    path: "/vn/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___vn"
  }, {
    path: "/vn/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___vn"
  }, {
    path: "/vn/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___vn"
  }, {
    path: "/vn/user",
    component: _d2874cbc,
    name: "user___vn",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___vn"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___vn"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___vn"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___vn"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___vn"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___vn"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___vn"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___vn"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___vn"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___vn"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___vn"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___vn"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___vn"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___vn"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___vn"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___vn"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___vn"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___vn"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___vn"
    }]
  }, {
    path: "/zh/about-us",
    component: _3595fc96,
    name: "about-us___zh"
  }, {
    path: "/zh/bug-bounty",
    component: _5f2c483b,
    name: "bug-bounty___zh"
  }, {
    path: "/zh/business",
    component: _110698d2,
    name: "business___zh"
  }, {
    path: "/zh/buy-sell-crypto",
    component: _442a0cce,
    name: "buy-sell-crypto___zh"
  }, {
    path: "/zh/c-freezer",
    component: _74de2de6,
    name: "c-freezer___zh"
  }, {
    path: "/zh/claim-system",
    component: _42bc330c,
    name: "claim-system___zh"
  }, {
    path: "/zh/contact-us",
    component: _6ecc7202,
    name: "contact-us___zh"
  }, {
    path: "/zh/crypto-campus",
    component: _b7b7e2b8,
    name: "crypto-campus___zh"
  }, {
    path: "/zh/crypto-cards",
    component: _11dc302e,
    name: "crypto-cards___zh"
  }, {
    path: "/zh/faq",
    component: _2388d3e2,
    name: "faq___zh"
  }, {
    path: "/zh/fees",
    component: _c7fcdaf8,
    name: "fees___zh"
  }, {
    path: "/zh/latin-america",
    component: _e402d304,
    name: "latin-america___zh"
  }, {
    path: "/zh/legal-notice",
    component: _75e25a7a,
    name: "legal-notice___zh"
  }, {
    path: "/zh/listing",
    component: _e0b27406,
    name: "listing___zh"
  }, {
    path: "/zh/login",
    component: _6e8f76bc,
    name: "login___zh"
  }, {
    path: "/zh/market-info",
    component: _4937e518,
    name: "market-info___zh"
  }, {
    path: "/zh/network",
    component: _0eb8feb2,
    name: "network___zh"
  }, {
    path: "/zh/not-found",
    component: _1c872141,
    name: "not-found___zh"
  }, {
    path: "/zh/privacy-notice",
    component: _694856d4,
    name: "privacy-notice___zh"
  }, {
    path: "/zh/privacy-policy",
    component: _13eba82e,
    name: "privacy-policy___zh"
  }, {
    path: "/zh/quick-exchange",
    component: _6d6b95cc,
    name: "quick-exchange___zh"
  }, {
    path: "/zh/register",
    component: _8c680e0c,
    name: "register___zh"
  }, {
    path: "/zh/reset-password",
    component: _77ee0520,
    name: "reset-password___zh"
  }, {
    path: "/zh/risks-disclosure-statement",
    component: _192d4a4f,
    name: "risks-disclosure-statement___zh"
  }, {
    path: "/zh/support",
    component: _8c08036a,
    name: "support___zh"
  }, {
    path: "/zh/terms-of-service",
    component: _5d5639c8,
    name: "terms-of-service___zh"
  }, {
    path: "/zh/terms-of-use",
    component: _6eaba86e,
    name: "terms-of-use___zh"
  }, {
    path: "/zh/trading-competition",
    component: _7e8b61e2,
    name: "trading-competition___zh"
  }, {
    path: "/zh/user",
    component: _d2874cbc,
    name: "user___zh",
    children: [{
      path: "api",
      component: _3e1bef70,
      name: "user-api___zh"
    }, {
      path: "history",
      component: _2ec333ea,
      name: "user-history___zh"
    }, {
      path: "profile",
      component: _798a42bf,
      name: "user-profile___zh"
    }, {
      path: "referrals",
      component: _328ef28c,
      name: "user-referrals___zh"
    }, {
      path: "ticket",
      component: _9b0962e8,
      name: "user-ticket___zh"
    }, {
      path: "verification",
      component: _639a931b,
      name: "user-verification___zh"
    }, {
      path: "history/active-orders",
      component: _0e6e55b2,
      name: "user-history-active-orders___zh"
    }, {
      path: "history/deposit",
      component: _37813c8e,
      name: "user-history-deposit___zh"
    }, {
      path: "history/orders",
      component: _0444ee40,
      name: "user-history-orders___zh"
    }, {
      path: "history/trades",
      component: _73fc7a6a,
      name: "user-history-trades___zh"
    }, {
      path: "history/withdraw",
      component: _fabe67b6,
      name: "user-history-withdraw___zh"
    }, {
      path: "security/change-password",
      component: _dc27616e,
      name: "user-security-change-password___zh"
    }, {
      path: "security/saved-addresses",
      component: _8d8cabe6,
      name: "user-security-saved-addresses___zh"
    }, {
      path: "security/security-sessions",
      component: _ddc1208a,
      name: "user-security-security-sessions___zh"
    }, {
      path: "security/two-factor-verification",
      component: _520dc609,
      name: "user-security-two-factor-verification___zh"
    }, {
      path: "wallet/margin",
      component: _4ebe639a,
      name: "user-wallet-margin___zh"
    }, {
      path: "wallet/wallet-list",
      component: _1500f648,
      name: "user-wallet-wallet-list___zh"
    }, {
      path: "wallet/deposit/:ticker?",
      component: _38fe6e5f,
      name: "user-wallet-deposit-ticker___zh"
    }, {
      path: "wallet/withdraw/:ticker?",
      component: _a9afa2e6,
      name: "user-wallet-withdraw-ticker___zh"
    }]
  }, {
    path: "/ar/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___ar"
  }, {
    path: "/ar/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___ar"
  }, {
    path: "/de/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___de"
  }, {
    path: "/de/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___de"
  }, {
    path: "/en/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___en"
  }, {
    path: "/en/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___en"
  }, {
    path: "/es/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___es"
  }, {
    path: "/es/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___es"
  }, {
    path: "/fl/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___fl"
  }, {
    path: "/fl/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___fl"
  }, {
    path: "/fr/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___fr"
  }, {
    path: "/fr/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___fr"
  }, {
    path: "/hi/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___hi"
  }, {
    path: "/hi/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___hi"
  }, {
    path: "/id/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___id"
  }, {
    path: "/id/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___id"
  }, {
    path: "/ko/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___ko"
  }, {
    path: "/ko/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___ko"
  }, {
    path: "/pt/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___pt"
  }, {
    path: "/pt/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___pt"
  }, {
    path: "/ru/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___ru"
  }, {
    path: "/ru/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___ru"
  }, {
    path: "/sr/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___sr"
  }, {
    path: "/sr/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___sr"
  }, {
    path: "/th/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___th"
  }, {
    path: "/th/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___th"
  }, {
    path: "/tr/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___tr"
  }, {
    path: "/tr/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___tr"
  }, {
    path: "/ua/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___ua"
  }, {
    path: "/ua/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___ua"
  }, {
    path: "/vn/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___vn"
  }, {
    path: "/vn/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___vn"
  }, {
    path: "/zh/c-freezer/history",
    component: _ae5251e2,
    name: "c-freezer-history___zh"
  }, {
    path: "/zh/trading-competition/history",
    component: _2f604b58,
    name: "trading-competition-history___zh"
  }, {
    path: "/ar/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___ar"
  }, {
    path: "/ar/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___ar"
  }, {
    path: "/ar/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___ar"
  }, {
    path: "/ar/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___ar"
  }, {
    path: "/ar/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___ar"
  }, {
    path: "/de/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___de"
  }, {
    path: "/de/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___de"
  }, {
    path: "/de/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___de"
  }, {
    path: "/de/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___de"
  }, {
    path: "/de/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___de"
  }, {
    path: "/en/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___en"
  }, {
    path: "/en/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___en"
  }, {
    path: "/en/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___en"
  }, {
    path: "/en/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___en"
  }, {
    path: "/en/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___en"
  }, {
    path: "/es/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___es"
  }, {
    path: "/es/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___es"
  }, {
    path: "/es/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___es"
  }, {
    path: "/es/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___es"
  }, {
    path: "/es/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___es"
  }, {
    path: "/fl/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___fl"
  }, {
    path: "/fl/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___fl"
  }, {
    path: "/fl/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___fl"
  }, {
    path: "/fl/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___fl"
  }, {
    path: "/fl/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___fl"
  }, {
    path: "/fr/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___fr"
  }, {
    path: "/fr/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___fr"
  }, {
    path: "/fr/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___fr"
  }, {
    path: "/fr/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___fr"
  }, {
    path: "/fr/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___fr"
  }, {
    path: "/hi/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___hi"
  }, {
    path: "/hi/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___hi"
  }, {
    path: "/hi/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___hi"
  }, {
    path: "/hi/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___hi"
  }, {
    path: "/hi/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___hi"
  }, {
    path: "/id/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___id"
  }, {
    path: "/id/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___id"
  }, {
    path: "/id/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___id"
  }, {
    path: "/id/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___id"
  }, {
    path: "/id/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___id"
  }, {
    path: "/ko/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___ko"
  }, {
    path: "/ko/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___ko"
  }, {
    path: "/ko/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___ko"
  }, {
    path: "/ko/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___ko"
  }, {
    path: "/ko/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___ko"
  }, {
    path: "/pt/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___pt"
  }, {
    path: "/pt/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___pt"
  }, {
    path: "/pt/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___pt"
  }, {
    path: "/pt/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___pt"
  }, {
    path: "/pt/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___pt"
  }, {
    path: "/ru/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___ru"
  }, {
    path: "/ru/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___ru"
  }, {
    path: "/ru/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___ru"
  }, {
    path: "/ru/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___ru"
  }, {
    path: "/ru/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___ru"
  }, {
    path: "/sr/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___sr"
  }, {
    path: "/sr/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___sr"
  }, {
    path: "/sr/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___sr"
  }, {
    path: "/sr/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___sr"
  }, {
    path: "/sr/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___sr"
  }, {
    path: "/th/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___th"
  }, {
    path: "/th/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___th"
  }, {
    path: "/th/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___th"
  }, {
    path: "/th/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___th"
  }, {
    path: "/th/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___th"
  }, {
    path: "/tr/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___tr"
  }, {
    path: "/tr/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___tr"
  }, {
    path: "/tr/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___tr"
  }, {
    path: "/tr/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___tr"
  }, {
    path: "/tr/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___tr"
  }, {
    path: "/ua/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___ua"
  }, {
    path: "/ua/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___ua"
  }, {
    path: "/ua/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___ua"
  }, {
    path: "/ua/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___ua"
  }, {
    path: "/ua/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___ua"
  }, {
    path: "/vn/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___vn"
  }, {
    path: "/vn/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___vn"
  }, {
    path: "/vn/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___vn"
  }, {
    path: "/vn/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___vn"
  }, {
    path: "/vn/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___vn"
  }, {
    path: "/zh/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___zh"
  }, {
    path: "/zh/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___zh"
  }, {
    path: "/zh/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___zh"
  }, {
    path: "/zh/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___zh"
  }, {
    path: "/zh/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___zh"
  }, {
    path: "/ar/markets/:id?",
    component: _35f0235b,
    name: "markets-id___ar"
  }, {
    path: "/ar/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___ar"
  }, {
    path: "/c-freezer/item/:ticker",
    component: _1954857c,
    name: "c-freezer-item-ticker___en___default"
  }, {
    path: "/c-freezer/plan/:id",
    component: _6cb84e2e,
    name: "c-freezer-plan-id___en___default"
  }, {
    path: "/de/markets/:id?",
    component: _35f0235b,
    name: "markets-id___de"
  }, {
    path: "/de/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___de"
  }, {
    path: "/en/markets/:id?",
    component: _35f0235b,
    name: "markets-id___en"
  }, {
    path: "/en/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___en"
  }, {
    path: "/es/markets/:id?",
    component: _35f0235b,
    name: "markets-id___es"
  }, {
    path: "/es/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___es"
  }, {
    path: "/fl/markets/:id?",
    component: _35f0235b,
    name: "markets-id___fl"
  }, {
    path: "/fl/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___fl"
  }, {
    path: "/fr/markets/:id?",
    component: _35f0235b,
    name: "markets-id___fr"
  }, {
    path: "/fr/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___fr"
  }, {
    path: "/hi/markets/:id?",
    component: _35f0235b,
    name: "markets-id___hi"
  }, {
    path: "/hi/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___hi"
  }, {
    path: "/id/markets/:id?",
    component: _35f0235b,
    name: "markets-id___id"
  }, {
    path: "/id/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___id"
  }, {
    path: "/ko/markets/:id?",
    component: _35f0235b,
    name: "markets-id___ko"
  }, {
    path: "/ko/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___ko"
  }, {
    path: "/pt/markets/:id?",
    component: _35f0235b,
    name: "markets-id___pt"
  }, {
    path: "/pt/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___pt"
  }, {
    path: "/ru/markets/:id?",
    component: _35f0235b,
    name: "markets-id___ru"
  }, {
    path: "/ru/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___ru"
  }, {
    path: "/sr/markets/:id?",
    component: _35f0235b,
    name: "markets-id___sr"
  }, {
    path: "/sr/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___sr"
  }, {
    path: "/support/ticket/:id?",
    component: _543a07e4,
    name: "support-ticket-id___en___default"
  }, {
    path: "/th/markets/:id?",
    component: _35f0235b,
    name: "markets-id___th"
  }, {
    path: "/th/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___th"
  }, {
    path: "/tr/markets/:id?",
    component: _35f0235b,
    name: "markets-id___tr"
  }, {
    path: "/tr/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___tr"
  }, {
    path: "/trading-competition/detail/:id",
    component: _31031e0f,
    name: "trading-competition-detail-id___en___default"
  }, {
    path: "/trading-competition/plan/:ticker",
    component: _12dd9016,
    name: "trading-competition-plan-ticker___en___default"
  }, {
    path: "/ua/markets/:id?",
    component: _35f0235b,
    name: "markets-id___ua"
  }, {
    path: "/ua/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___ua"
  }, {
    path: "/vn/markets/:id?",
    component: _35f0235b,
    name: "markets-id___vn"
  }, {
    path: "/vn/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___vn"
  }, {
    path: "/zh/markets/:id?",
    component: _35f0235b,
    name: "markets-id___zh"
  }, {
    path: "/zh/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___zh"
  }, {
    path: "/ar/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___ar"
  }, {
    path: "/ar/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___ar"
  }, {
    path: "/ar/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___ar"
  }, {
    path: "/de/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___de"
  }, {
    path: "/de/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___de"
  }, {
    path: "/de/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___de"
  }, {
    path: "/en/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___en"
  }, {
    path: "/en/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___en"
  }, {
    path: "/en/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___en"
  }, {
    path: "/es/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___es"
  }, {
    path: "/es/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___es"
  }, {
    path: "/es/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___es"
  }, {
    path: "/fl/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___fl"
  }, {
    path: "/fl/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___fl"
  }, {
    path: "/fl/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___fl"
  }, {
    path: "/fr/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___fr"
  }, {
    path: "/fr/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___fr"
  }, {
    path: "/fr/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___fr"
  }, {
    path: "/hi/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___hi"
  }, {
    path: "/hi/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___hi"
  }, {
    path: "/hi/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___hi"
  }, {
    path: "/id/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___id"
  }, {
    path: "/id/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___id"
  }, {
    path: "/id/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___id"
  }, {
    path: "/ko/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___ko"
  }, {
    path: "/ko/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___ko"
  }, {
    path: "/ko/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___ko"
  }, {
    path: "/pt/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___pt"
  }, {
    path: "/pt/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___pt"
  }, {
    path: "/pt/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___pt"
  }, {
    path: "/ru/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___ru"
  }, {
    path: "/ru/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___ru"
  }, {
    path: "/ru/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___ru"
  }, {
    path: "/sr/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___sr"
  }, {
    path: "/sr/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___sr"
  }, {
    path: "/sr/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___sr"
  }, {
    path: "/th/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___th"
  }, {
    path: "/th/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___th"
  }, {
    path: "/th/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___th"
  }, {
    path: "/tr/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___tr"
  }, {
    path: "/tr/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___tr"
  }, {
    path: "/tr/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___tr"
  }, {
    path: "/ua/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___ua"
  }, {
    path: "/ua/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___ua"
  }, {
    path: "/ua/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___ua"
  }, {
    path: "/vn/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___vn"
  }, {
    path: "/vn/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___vn"
  }, {
    path: "/vn/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___vn"
  }, {
    path: "/zh/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___zh"
  }, {
    path: "/zh/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___zh"
  }, {
    path: "/zh/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___zh"
  }, {
    path: "/exchange/:pair",
    name: "ExchangePair",
    beforeEnter: (to, _, next) => {
          next(to.params.pair.includes('_') ? `/exchange/${to.params.pair.replace('_', '/')}` : '/exchange');
        }
  }, {
    path: "/markets/:id?",
    component: _35f0235b,
    name: "markets-id___en___default"
  }, {
    path: "/referral/:id?",
    component: _0b6dec9f,
    name: "referral-id___en___default"
  }, {
    path: "/trade/:pair",
    name: "TradePair",
    beforeEnter: (to, _, next) => {
          next(to.params.pair.includes('_') ? `/exchange/${to.params.pair.replace('_', '/')}` : '/exchange');
        }
  }, {
    path: "/trading/:pair",
    name: "TradingPair",
    beforeEnter: (to, _, next) => {
          next(to.params.pair.includes('_') ? `/exchange/${to.params.pair.replace('_', '/')}` : '/exchange');
        }
  }, {
    path: "/claim-system/:ticker?/:fundId",
    component: _25d2f621,
    name: "claim-system-ticker-fundId___en___default"
  }, {
    path: "/exchange/:left?/:right?",
    component: _4825ecea,
    name: "exchange-left-right___en___default"
  }, {
    path: "/trading-view/:left?/:right?",
    component: _4e7b65c6,
    name: "trading-view-left-right___en___default"
  }, {
    path: "/",
    component: _01debcea,
    name: "index___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
