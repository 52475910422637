var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exc-header",class:{
    'exc-header__is-trade': _vm.isTrade,
  }},[_c('div',{staticClass:"exc-header__container",class:{
      // 'bg--color-01': onlyTheme === 'dark',
      // 'bg--color-08': onlyTheme === 'light',
      // 'bg--color-01': !onlyTheme,
      'bg--color-01': _vm.isTrade,
      'bg--color-08': !_vm.isTrade,
      'sha--4': !_vm.isTrade,
      'hidden-header-on-mobile': _vm.isSideBarOpen
    }},[_vm._t("logo",function(){return [_c('exc-header-nav-item',{attrs:{"to":'/'},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('div',{staticClass:"exc-header__logo pl-32 pr-16"},[_c('exc-full-logo')],1)]},proxy:true}])})]}),_vm._v(" "),_c('div',{staticClass:"exc-header__desk-left"},[_c('div',{staticClass:"exc-header__desk-left-container"},_vm._l((_vm.navLinksFiltered),function(link){return _c('exc-header-desk-nav-creator',{key:link.name,attrs:{"link":link,"only-theme":_vm.onlyTheme}})}),1)]),_vm._v(" "),_c('div',{staticClass:"exc-header__desk-right ml-auto"},[_c('div',{staticClass:"exc-header__desk-right-container"},[_vm._t("desktop")],2)]),_vm._v(" "),_c('exc-header-mob',{staticClass:"exc-header__mobile ml-auto",attrs:{"nav-links":_vm.navLinksFiltered,"only-theme":_vm.onlyTheme},on:{"toggle":_vm.toggleSidebar},scopedSlots:_vm._u([{key:"header-mobile-right-part",fn:function(){return [_vm._t("header-mobile-right-part")]},proxy:true},{key:"close-icon",fn:function(ref){
    var toggleSidebar = ref.toggleSidebar;
return [_vm._t("close-icon",null,null,{toggleSidebar: toggleSidebar})]}},{key:"mobile-auth",fn:function(ref){
    var toggleSidebar = ref.toggleSidebar;
return [_vm._t("mobile-auth",null,null,{toggleSidebar: toggleSidebar})]}},{key:"mobile-custom",fn:function(ref){
    var toggleSidebar = ref.toggleSidebar;
return [_vm._t("mobile-custom",null,null,{toggleSidebar: toggleSidebar})]}},{key:"mobile-menu-icon",fn:function(){return [_vm._t("mobile-menu-icon")]},proxy:true}],null,true)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }